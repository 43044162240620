import axios from "axios";
import { workwiseServices } from "../Workw-Api/api";

const konn = "https://grid.workw.com";
// const konn = "http://localhost:8080";

export const updateSheet = async (id, data) => {
    try {
        await axios.post(
            `${konn}/api/update`,
            {
                spreadsheetID: id,
                spreadsheetData: data,
            }
        );
    } catch (e) {
        console.error(e);
    }
};
export const deleteSheet = async (id, data, setSavingData) => {
    try {
        await axios.post(
            `${konn}/api/sheet/delete`,
            {
                spreadsheetID: id,
                sheetName: data.sheetName,
            }
        );
        workwiseServices.saveDocumentlastUpdate(id, setSavingData, data.sheets);
    } catch (e) {
        console.error(e);
    }
};
export const renameSheet = async (id, data, setSavingData) => {
    try {
        await axios.post(
            `${konn}/api/sheet/rename`,
            {
                spreadsheetID: id,
                sheetName: data.oldName,
                newSheetName: data.newName,
            }
        );
        workwiseServices.saveDocumentlastUpdate(id, setSavingData, data.sheets);
    } catch (e) {
        console.error(e);
    }
};
export const loadSpreadsheetData = async (id) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const milegridURL = url.searchParams.get("gridname");

    try {
        document.getElementById("milegrid-url").innerHTML = milegridURL;
        const response = await axios.get(
            `${konn}/api/${id}`
        );
        let filteredSheetData;
        const getSheetData = response.data[0].spreadsheetData;
        if (getSheetData) {
            filteredSheetData = getSheetData.filter(word => Object.keys(word).length > 0);
            return filteredSheetData;
        }
    } catch (error) {
        console.log(error);
    }
};
export const saveImportDataToDb = async (data) => {
    try {
        await axios.post(`${konn}/api/import/internal`, { ...data });

    }
    catch (error) {
        console.log(error);
    }

}