import React, { useState } from "react";
import { Menu, Dropdown, Modal, message, Form, Input } from "antd";
import classes from "./navTabs.module.css";
import XLSX from "xlsx";
import { useSpreadSheet } from "../../context/spreadsheet-context";
import SubMenu from "antd/lib/menu/SubMenu";
import { MenuItem } from "rc-menu";
import axios from "axios";
import { useParams } from "react-router-dom";
import { emitLoadImport } from "../../socket";
import { useDocument } from "../../Pages/SpreadsheetContainer/documentInfoContex";
import { saveAs } from "file-saver";
import { saveImportDataToDb } from "../../utils/api";
import { compress, decompress } from 'compress-json';

export const FileTab = () => {
  const { spreadsheetInstanceRef } = useSpreadSheet();
  const { documentInfo, sheets, setSheets, setSavingData } = useDocument();
  const { id } = useParams();
  const isMobile = window.screen.width < 769;
  const [selectedExportType, setSelectedExportType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [form] = Form.useForm();


  // const konn = "http://192.168.18.81:8080";
  const konn = "https://grid.workw.com";
  // const konn = "http://localhost:8080";

  // const [someBook, setSomeBook] = useState({});
  // console.log("modal",isModalVisible)
  let stoxWorkbook;
  let newBook;

  const stox = (wb) => {
    var out = [];
    wb.SheetNames.forEach(function (name) {
      var o = { name: name, rows: {} };
      var ws = wb.Sheets[name];
      var aoa = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
      aoa.forEach(function (r, i) {
        var cells = {};
        r.forEach(function (c, j) {
          cells[j] = { text: c };
        });
        o.rows[i] = { cells: cells };
      });
      out.push(o);
    });
    return out;
  };
  /* eslint-disable */
  const xtos = (sdata) => {
    var out = XLSX.utils.book_new();

    Array.isArray(sdata) && sdata.forEach(function (xws) {
      var aoa = [[]];
      var rowobj = xws.rows;

      // Check if the sheet has any data
      if (rowobj && typeof rowobj.len === 'number' && rowobj.len > 0) {
        for (var ri = 0; ri < rowobj.len; ++ri) {
          var row = rowobj[ri];
          if (!row) continue;
          aoa[ri] = [];
          Object.keys(row.cells).forEach(function (k) {
            var idx = +k;
            if (isNaN(idx)) return;
            aoa[ri][idx] = row.cells[k].text;
          });
        }

        // Only add the sheet if it contains data
        if (aoa.length > 1 || (aoa.length === 1 && aoa[0].length > 0)) {
          var ws = XLSX.utils.aoa_to_sheet(aoa);
          XLSX.utils.book_append_sheet(out, ws, xws.name);
        }
      }
    });
    return out;
  }
  /* eslint-enable */


  const handleFileExportAsPDF = () => {
    try {
      const wb = spreadsheetInstanceRef.current.getData();
      const new_wb = xtos(wb);

      if (!new_wb || !new_wb.SheetNames) {
        message.error('Failed to process the spreadsheet data.');
        return;
      }

      if (new_wb.SheetNames.length === 0) {
        message.error('The document is empty. Please add some data before exporting.');
        return;
      }

      let sheetsHTML = [];
      let hasData = false;

      new_wb.SheetNames.forEach(sheetName => {
        const sheet = new_wb.Sheets[sheetName];
        const sheetHTML = XLSX.utils.sheet_to_html(sheet);

        if (Object.keys(sheet).length > 1) {
          hasData = true;
        }

        sheetsHTML.push(sheetHTML);
      });

      if (!hasData) {
        message.error('All sheets are empty. Please add some data before exporting.');
        return;
      }

      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);

      const doc = iframe.contentWindow.document;
      const style = `
      <style>
        @page {
          size: landscape;
          margin: 0;
        }
      </style>`;

      doc.open();
      doc.write(style);
      sheetsHTML.forEach(sHTML => doc.write(sHTML));
      doc.close();

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      setTimeout(() => {
        document.body.removeChild(iframe);
        // message.success('Document successfully exported as PDF.');
      }, 1000);

    } catch (error) {
      console.error('Export error:', error);
      message.error('An error occurred while exporting the document. Please try again.');
    }
  };
  const handleFileExportAsGrid = () => {
    try {
      if (!sheets || sheets.length === 0) {
        throw new Error("No data available to export.");
      }

      // Compress the sheets data
      const compressedData = compress(sheets);
      const content = JSON.stringify(compressedData);

      // Create a blob and save the file
      const blob = new Blob([content], { type: "application/json" });
      const exportFileName = fileName?.trim() || "Untitled";
      saveAs(blob, `${exportFileName}.grid`);
      message.success(`File successfully exported as ${exportFileName}.grid`);
    } catch (error) {
      throw new Error("Error during export");
    }
  };
  const handleFileExportAs = (ex) => {
    const wb = spreadsheetInstanceRef?.current?.getData();
    const new_wb = xtos(wb);

    if (!new_wb || !new_wb.SheetNames) {
      message.error('Failed to process the spreadsheet data.');
      return;
    }

    if (new_wb.SheetNames.length === 0) {
      message.error('The document is empty. Please add some data before exporting.');
      return;
    }

    let hasData = false;
    for (const sheetName of new_wb.SheetNames) {
      if (Object.keys(new_wb.Sheets[sheetName]).length > 1) { // > 1 because of '!ref' property
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      message.error('All sheets are empty. Please add some data before exporting.');
      return;
    }

    try {
      XLSX.writeFile(new_wb, `${fileName}.${ex}`);
      message.success(`File successfully exported as ${fileName}.${ex}`);
    } catch (error) {
      console.error('Export error:', error);
      message.error('An error occurred while exporting the file. Please try again.');
    }
  };

  const importGridInternalFile = async (file) => {
    try {
      const text = await file.text();
      const importedData = decompress(JSON.parse(text));
      // Validate imported data
      if (!Array.isArray(importedData)) {
        throw new Error("Invalid data structure. Expected an array.");
      }

      Modal.confirm({
        title: "Confirm",
        content: "Do you want to overwrite?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          const filteredSheetData = importedData.filter(word => Object.keys(word).length > 0) || [];
          saveImportDataToDb({
            spreadsheetID: id,
            spreadsheetData: importedData,
          });

          if (filteredSheetData.length > 0) {
            spreadsheetInstanceRef.current.loadData(
              filteredSheetData
            );
            setSheets(importedData);
          }
          document.querySelector('#fileSelect').value = '';
        },
        onCancel: () => handleNo(),
      });
    } catch (error) {
      document.querySelector('#fileSelect').value = '';
      console.error("Failed to parse .grid file:", error);
      message.error("Invalid .grid file. Please check the file and try again.");
    }
  };

  const handleFileImportOnChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const fileExtension = file?.name?.split(".").pop().toLowerCase();
    if (fileExtension === 'grid') {
      importGridInternalFile(file);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", (e) => {
      let fileData = e.target.result;
      // console.log("fileData: ", fileData);
      // console.log('inside listener');
      // const JSONres = JSON.parse(JSON.stringify(fileData));
      // spreadsheetInstanceRef.current.loadData(fileData);
      const dataBuffer = new Uint8Array(fileData);
      // console.log("Data Buffer: ", dataBuffer);
      const workbook = XLSX.read(dataBuffer, { type: "array" });
      stoxWorkbook = stox(workbook);
      // setSomeBook(stoxWorkbook);
      Modal.confirm({
        title: 'Confirm',
        content: 'Do you want to overwrite ?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => handleOk(stoxWorkbook),
        onCancel: () => handleNo()
      })
      // newBook = spreadsheetInstanceRef.current.getData();
      // console.log(someBook, "WHAT IS THIS");
      // setModalVisible(true);
    });
    reader.readAsArrayBuffer(file);
    // console.log('after reader');
  };

  const handleOk = async (s) => {
    // console.log(s);
    spreadsheetInstanceRef.current.loadData(s);
    newBook = spreadsheetInstanceRef.current.getData();
    emitLoadImport(newBook);
    document.querySelector('#fileSelect').value = '';
    // let temparr = newBook.map(val => Object.values(val));
    // console.log(temparr, "TempArr");
    console.log(newBook);
    for (const page of newBook) {
      try {
        await axios.post(
          `${konn}/api/update`,
          {
            spreadsheetID: id,
            spreadsheetData: page,
          }
        );
      } catch (e) {
        console.log(e);
      }
      // console.log(page);
    }
  };

  const handleNo = () => {
    document.querySelector('#fileSelect').value = '';
  };

  const handleMenuClick = (type) => {
    setSelectedExportType(type);
    const fileName = documentInfo?.name || "Untitled document";
    form.setFieldsValue({ fileName: fileName });
    setFileName(fileName);
    setIsModalVisible(true);
  };

  const menu = (
    <Menu>
      <SubMenu key="sub01" title="Import File (.xlsx)">
        <MenuItem key='sub01.1'>
          <input
            className={classes["upload-input"]}
            onChange={handleFileImportOnChange}
            type="file"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.grid"
            // accept = "text/comma-separated-values"
            id="fileSelect"
            runat="server"
          />
        </MenuItem>
      </SubMenu>
      <SubMenu key="sub02" title="Export As">
        <MenuItem key="sub02.1" onClick={() => handleMenuClick("xlsx")}>
          Export (.xlsx)
        </MenuItem>
        <MenuItem key="sub02.2" onClick={() => handleMenuClick("ods")}>
          Export (.ods)
        </MenuItem>
        <MenuItem key="sub02.3" onClick={() => handleMenuClick("csv")}>
          Export (.csv)
        </MenuItem>
        <MenuItem key="sub02.4" onClick={() => handleMenuClick("html")}>
          Export (.html)
        </MenuItem>
        <MenuItem key="sub02.5" onClick={handleFileExportAsPDF}>
          Export (.pdf)
        </MenuItem>
        <MenuItem key="sub02.6" onClick={() => handleMenuClick("grid")}>
          Export (.grid)
        </MenuItem>
      </SubMenu>
    </Menu>
  );

  const handleFileNameChange = (e) => {
    setFileName(e.target.value)
  };

  const handleExport = () => {
    if (!selectedExportType) {
      message.warning("Please select an export type.");
      return;
    }
    form
      .validateFields()
      .then(() => {
        try {
          switch (selectedExportType) {
            case "xlsx":
              handleFileExportAs("xlsx");
              break;
            case "ods":
              handleFileExportAs("ods");
              break;
            case "csv":
              handleFileExportAs("csv");
              break;
            case "html":
              handleFileExportAs("html");
              break;
            case "grid":
              handleFileExportAsGrid();
              break;
            default:
              console.warn("Unknown export type:", selectedExportType);
              message.error("Unsupported export type selected.");
              return;
          }
          setIsModalVisible(false);
        } catch (exportError) {
          console.error("Export failed:", exportError);
          message.error("An error occurred during export. Please try again.");
        }
      })
      .catch((validationError) => {
        console.error("Validation failed:", validationError);
        message.error("Please enter a valid file name.");
      });
  };

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} arrow>
        <div style={{ fontSize: isMobile ? '11px' : '14px' }} className={classes.navTabButton} onClick={(e) => e.preventDefault()}>
          File
        </div>
      </Dropdown>
      <Modal
        title="Set File Name"
        visible={isModalVisible}
        onOk={handleExport}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label="File Name"
            name="fileName"
            rules={[
              {
                required: true,
                message: "Please enter a file name.",
              },
              {
                pattern: /^[^<>:;,?"*|/\\]+$/,
                message: "File name contains invalid characters.",
              },
            ]}
          >
            <Input
              value={fileName}
              onChange={handleFileNameChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
